export default {
  pageTitle: "Gonçalo Roquette - Therapies",
  header: {
    home: "Home",
    changeLangButton: "🇵🇹",
    cta: "Book now",
    therapies: "Therapies",
    therapist: "Therapist",
    testimonials: "Testimonials",
    videos: "Videos",
    prices: "Prices",
    contacts: "Contacts",
    logoAlt: "Gonçalo Roquette",
  },
  hero: {
    title: "Gonçalo Roquette",
    cta: "Book a session",
    knowMore: "Know more",
  },
  about: {
    title: "Therapist",
    description: `I´m Gonçalo, 45 years old.

I was born in Lisbon, I grew up between the city (Lisbon) and the countryside (Sintra) wihch led me to have a strong connection to Nature, The chaos of the city contrasted with the calm of the contryside, It made me feel good, From an early age i was a curious boy, which led me to look for different ways to express myself as a human being.

I wanted to see the World, I traveled through Europe, South America, Middle East, Africa and Asia, It was the trip i took at age of 18 for 3 months through India and Nepal that awakened me to Sripituality, Spirituality has always been present in my life but i didn´t have this awareness, Today i have.

I started by studing Marketing and Advertising because i always had my head boiling with ideas, But it wasn´t enough for me to calm thr turmoil that was going on my head, I went to study Photography and Video, Here i found the right way to express myself.., I worked in this area for about 18 years, I work as a freelancer for several Production Companies and had my own Film Production Company for 7 years, I did a little bit of everything (Tv Shows, Fashion, Documentaries, Art, Short Films, etc), After 18 years i felt exhausted, I had to change my life, I had to slow down, The Aidiovisual area is quite stressful, It´s all for yesterday with no space to live in the present.

In 2019 i started training as a Reiki Master, Crystal Therapy and Sound Therapy, It was the change i needed, My life changed radically, I became a Therapist.

That´s why i´m here for, To be able to share what i´ve learned, help and make people´s lives more balance both Physically, Mentally, Emotionally and Spiritually.

Reconnect people with themselves, with their Original Being.

A Being of Love, Peace and Harmony.`,
    meTitle: "About myself",
    certificatesTitle: "Trainings and Certifications",
    certificates: `* Sound Therapy and Massage
International School of Tibetan Bowls
Master Trainer: Gong Master Miguel Lourenço 

* Sound Therapy
Center of Excellence

* Reiki Shoden Level 1 
Light Work
Master Trainer: Reiki Master Armando Leal

* Reiki Okuden Level 2
Light Work
Master Trainer: Reiki Master Armando Leal

* Reiki Shinpiden and Gokukaiden Level 3
Light Work
Master Trainer: Reiki Master Armando Leal

* Suishou Crystal Healing Therapy
CENIF Portuguese Research Center of Complementary Therapies
Master Trainer: Reiki Master Joâo Magalhães

* Crystal World
Us Space
Master Trainer: Carla Liliana Oliveira

* Quantum Spirituality
Pedro Frias Academy
Master Trainer: Sipitual Coach Pedro Frias

* Channeling and Therapeutics with the Beings of Light
Light Work
Master Trainer: Reiki Master Armando Leal`,
    imageAlt: "Gonçalo Roquette",
  },
  cta: {
    title: `"If you want to find the secrets of the Universe, think in terms of energy, frequency and vibration" - Nikola Tesla`,
    cta: "Book a session",
  },
  cta2: {
    title: `"Future Medicine will be the medicine of frequencies" - Albert Einstein`,
    cta: "Book a session",
  },
  pricing: {
    title: "Prices",
    description: "",
    cards: [
      {
        title: "Therapy and Sound Massage",
        popular: "Popular",
        price: 60,
        cta: "Book now",
        items: ["Duration: 60 minutes"],
      },
      {
        title: "Sound Bath Individual",
        price: 60,
        cta: "Book now",
        items: ["Duration: 60 minutes"],
      },
      {
        title: "Sound Bath Group",
        price: 15,
        cta: "Book now",
        items: ["Duration: 60 minutes"],
      },
      {
        title: "Sound Bath Online",
        price: 8,
        cta: "Book now",
        items: ["Duration: 60 minutes"],
      },
      {
        title: "Reiki Usui",
        price: 45,
        cta: "Book now",
        items: ["Duration: 60 minutes"],
      },
      {
        title: "Crystal Therapy",
        price: 45,
        cta: "Book now",
        items: ["Duration: 60 minutes"],
      },
      {
        title: "Reiki with Crystals",
        price: 45,
        cta: "Book now",
        items: ["Duration: 60 minutes"],
      },
    ],
  },
  testimonials: {
    title: "Testimonials",
    items: [
      // {
      //   text: "An incredible experience! I do Reiki regularly, Yoga, meditation but had never experienced a sound bath, in fact, until very little time did not even know existed. It is incredible what the sound and vibrations of Tibetan bowls have provoked in me: a deep relaxation of body and mind, a calm and a peace that lasted several days after the session. The voice and serenity of Gonçalo, the confidence that transmits in what is doing and the affectionate form with which you receive us for this experience is anything ... The sound baths will undoubtedly be a practice to include in my routine with a viewIt is also an increasing connection to my inner self. Very grateful.",
      //   author: "Rita Freitas",
      // },
      {
        text: "An experience that goes far beyond expectation. During the session allows to release the mind and feel the body and the present. But the best sensation comes with the moments and hours to follow. A total relaxation accompanied by well-being.",
        author: "Diogo Teixeira",
      },
      {
        text: "Wonderful experience with the sound bath. Tibetan bowls emanate an intense energy that feels in the body. I just let myself go! Thank you!",
        author: "Vera Costa",
      },
      {
        text: "I recommend! Relaxing session, engaging and fluent in the dynamics of good vibrations emanated by the bowls. To repeat.",
        author: "António Santos",
      },
      // {
      //   text: "A very interesting experience, great relaxation of the body and mind providing sounds and sound vibrations interaction with the body and allow pleasantly special sensations. The sounds also give a sense of journey from the mind! Thank you for the contribution of well-being.",
      //   author: "Pedro Sebes",
      // },
      // {
      //   text: "I loved the session, a vibrant and meditative sound experience. I recommend!",
      //   author: "João Gomes",
      // },
      {
        text: "A fantastic relaxation experience, in which we lost in the sound and we feel hovering, feeling the body to expel the tensions.",
        author: "Joana Graça",
      },
    ],
  },
  videos: {
    title: "Videos",
    items: [
      {
        title: "Sound bath 8 minutes to transmit energies",
        description: "Use headphones for a better experience.",
        url: "https://www.youtube.com/watch?v=IOmdSS0JzJc",
      },
      {
        title: "Therapeutic Bowl number 6",
        description: `Diameter: 21cm
Note: F
It's the ideal bowl for the throat, larynx, pharynx, trachea, vocal cords.`,
        url: "https://www.youtube.com/watch?v=sXHK_lOU-44",
      },
      {
        title: "Therapeutic Bowl number 4",
        description: `Diameter: 13cm
Note: F#
Ideal bowl to Relieve Sinosite and Allergies.`,
        url: "https://www.youtube.com/watch?v=-zlAB7JlxH4",
      },
      {
        title: "Therapeutic Bowl number 7",
        description: `Diameter: 18cm
Note: D
Indicated for stomach problems and as stimulating for intestines.
Ideal for those suffering from constipation.
Stomach pains, Digestion difficulties, ulcers. It does not solve reflux.
It's a bowl that can capture and emanate large amounts of energy.`,
        url: "https://www.youtube.com/watch?v=3mmpF74sMzA",
      },
      {
        title: "Therapeutic Bowl number 2",
        description: `Diameter: 24cm
Note: D#
Ideal for feet, hands, muscles, tendons and joints.`,
        url: "https://www.youtube.com/watch?v=aQh-SPcCos4",
      },
      {
        title: "Sound bath 8 minutes to climb the energy",
        description: "Use headphones for a better experience",
        url: "https://www.youtube.com/watch?v=scylS7v2NaY",
      },
      {
        title: "REIKI SOUND HEALING",
        description: "",
        url: "https://www.youtube.com/watch?v=VT8IHRzFrds",
      },
    ],
  },
  therapies: {
    title: "Therapies",
    description:
      "Sound healing synchronizes brain waves to achive profound states of relaxation, helping to restore the normal vibratory frequencies of the cells in your body.",
    items: [
      {
        title: "Therapy and Sound Massage with Tibetan bowls and gongs",
        description: `Therapy and sound massage is the application of therapeutic Tibetan bowls on specific body zones with the intention of restoring the original and healthy vibration of the human being.

Promotes stress relief at various levels, the emotions become more calm and the clearest mind, causing a relaxing effect that lasts for several days after the session.

Tibetan bowls are considered one of the stronger sound therapy instruments for healing, an ancestral regeneration technique.

Benefits for Body and Mind:

* Sound is undoubtedly one of the most powerful healing media in that leads to the human being to different states of consciousness.
* Tibetan bowls and gongs harmonize (sound and vibration) and allow deep brain relaxation. When we relax concentration improves and emotional tensions and locks are relieved. Questions such as low self-esteem, fear, anger, anxiety, depression or insomnia can often be attenuated through the application of this therapy, also recommended to children with hyperactive disorders.
* In the physical plane, it is disposed of toxins from the body and the healthy memory of the cells is stimulated by causing non-healthy cells to self-destroy.
* The unique sounds of Tibetan bowls stabilize blood pressure and promote good circulation. They stimulate the digestive system as well as the immune fighting respiratory problems (eg asthma and bronchitis). Favor bone and muscular regeneration, as well as the relief of joint, sciatica or migraine pains.
* The sounds and vibrations of Tibetan bowls and gongs affect the 4 human levels, physicist, mental, emotional and spiritual.
* After a session, the body and mind are tuned into our interior. Tibetan bowls emit the sound and frequency that relive the basic DNA codes and activates the codes still asleep or deleted.

The physical effect of sound and vibration in the body:

* Deep relaxation and muscle regeneration.
* Relief of pain in joints, muscles, sciatica, headache and migraine, digestive system, spine injuries, shoulders and improvement of blood circulation.
* Improves the digestion process.
* Atoms, molecules, cells, tissues and organs work more synchronized.
* Improves energy flow.
* Disposal of body toxins.
* Improves the immune system.

Mental and emotional effect of sound and vibration:

* Relaxed mind, tensions and mental / emotional blocks are relieved.
* Best concentration.
* Internal feelings are activated and released.
* More energy and strength in quatidian life.
* Relieved mental or emotional pain.
* Improvement of self-confidence and increased creative and productive potential.

Spiritual effect of sound and vibration:

* Positive feeling of oneself.
* Better balance and harmony in day to day life.
* Experiences of ecstasy.
* Cleaning the Chakras (Energy Centers) and Nadis (Astral Channels)
* Open way for a more intense love vibration.`,
      },
      {
        title: "Meditation and Sound Bath with Tibetan bowls and gongs",
        description: `A sound bath is a meditative practice, a way to control anxiety, calm the nervous system and block all ideas and thoughts of your consciousness while connecting with your body.Lying on a yoga rug let yourself be carried by the sounds and vibrations of the Tibetan and Gongs, as well as the curative sound of other instruments used in sound therapy.

Benefits:

* Calms the body and the mind
* Reduces stress and anxiety
* Increases focus and clarity
* Diminui tension and fatigue
* Improves mood and reduces the feelings of anger
* Improves sleep
* Raise the feelings of spiritual well-being

After a sound bath session will feel a wide range of emotions, from happiness, ecstasy, deep relaxation, sensation of lightness, among other feelings of well-being.`,
      },
      {
        title: "Crystallotherapy",
        description: `Crystallotherapy consists of the soft application of crystals on the body with a view to the treatment of pathologies and relief of physical, mental and spiritual symptoms.

The crystals act as energy conductors, creating links between the energy dispersed by the body, restoring their natural balance.

The chemical composition of a crystal is very similar to that of the human being for this used thousands of years in internal cure processes.

And such as man, crystals, own their own energy vibratory frequency, which varies according to their characteristics.

The performance of a crystal over the human body is explained by the Law of Resonance, a physical phenomenon that records the transfer of energy from an oscillating body to another creating a much broader energy field. When the human body comes into contact with the crystals, the vibratory fields are tuned at a specific frequency that transmits information to the nervous system. From there these messages are sent to the cells, generating metabolic alterations of healing.

All crystals independently of their shape or composition have the ability to absorb, channel, conserve, concentrate and emit energy, amplifying it.

A crystal is a geometrically regular solid formation, which occurred at the formation of the planet Earth, as it underway through various metamorphic processes. Crystals are therefore often considered "records" of the planet, a kind of Earth DNA, since "witnessed" all the evolution of the planet.

Benefits of Crystallotherapy:

* Provides deep relaxation.
* Assists in the elimination of locks.
* Greater sense of well-being.
* Helps detoxify.
* Combat depression and stress.
* Helps combat allergies.
* Relieves headaches and muscle aches.
* Assists in vibrational elevation.
* Energy activation and chakras rebalancing
* Relieves negative feelings
* It enhances good sensations`,
      },
      {
        title: "Reiki Usui",
        description: `Reiki is a technique created in Japan by Master Mikao Usui. This therapy consists of the imposition of the hands for the transfer of vital energy from the universe through the patient therapist. In this way it is possible to align the body energy centers, known to Chakras, promoting energy equilibrium, needed to maintain physical and mental well-being.
During the session the therapist puts hands (with or without physical touch in the patient) on the patient's body in order to change the oscillation or vibration of energies bringing proven benefits such as pain relief and reducing anxiety and stress symptoms.

Main benefits:

* Decreases symptoms of anxiety and stress:
This therapy is an excellent ally for the treatment of anxiety due to the feeling of relaxation it provides. Promotes the feeling of inner peace.

* Help in the treatment of depression:
Through the application of hands imposition techniques it is possible to align the vital energy of the body and reduce the symptoms caused by depression, such as excessive sadness, physical fatigue and loss of energy and interest in the activities that previously generated pleasure.

* Reduction of chronic pain:
Relaxation produced during Reiki's practice contributes to the decrease in chronic pains as pain in spine and head, as it is able to reduce tensions and muscle fatigue. The effects on chronic pains can be felt in the first sessions, as the therapist will decelerate the energy of the patient, changing vibration and speed, producing waves aligned in the chakras equivalent to the affected bodies.

* Relieves the symptoms of insomnia:
The sense of well-being caused by Reiki therapy is able to increase the release of hormones such as serotonin and endorphine, which are excellent allies in combating insomnia.

* Improves quality of life:
Through Reiki improves the quality of life as it is a therapy that frees emotional tensions, making a cleaning in mind what promotes interior relaxation. Reiki generates in the patient feelings of hope, well-being, joy, peace and self-confidence.`,
      },
    ],
  },
  contacts: {
    contacts: "Contacts / Bookings",
    phoneLabel: "Mobile",
    phone: "+351 933 956 660",
    emailLabel: "E-Mail",
    email: "goncaloroquetteterapias@gmail.com",
    whatsapp: "351933956660",
    whatsappText: "I want to know more about sound therapies",
  },
  footer: {
    logoAlt: "Gonçalo Roquette",
    site: "goncaloroquette.com",
    instagram: "https://www.instagram.com/goncaloroquette/",
  },
  seo: {
    title: "Gonçalo Roquette - Therapies",
    description:
      "Therapies / Sound Therapy / Crystal Therapy / Traditional Reiki / Tibetan Bowls",
    url: "https://goncaloroquette.com/",
    image: "https://goncaloroquette.com/logo.png",
  },
};
